import React from "react";
import Layout from "../components/Layout/Layout";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import "../styles/pages/jobs.scss";

export default function Jobs() {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(
        relativePath: { eq: "images/Contact Page/Rectangle 7.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const imageData = data.desktop.childImageSharp.fluid;
  return (
    <Layout>
      <BackgroundImage
        Tag="section"
        className="contact-banner page-hero"
        fluid={imageData}
        backgroundColor={`#303d4e`}
      >
        <div className="container">
          <h2>DeltaMath Jobs</h2>
        </div>
      </BackgroundImage>

      <div className="container jobs">
        <h2>Roles</h2>
        <p>There are no open roles at this time.</p>
        <br />
        <br />
        <br />
        <br />
      </div>
    </Layout>
  );
}
